import React from 'react';
import {createStyles, Theme, withStyles, WithStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import PropTypes from "prop-types";
import {TranslatedText, translatedTexts} from "../lang";
import {Link} from "@material-ui/core";
const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });
export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export const MoreInfo = ({country}) => {
    const [open, setOpen] = React.useState(false);
    const textTranslated: translatedTexts = TranslatedText(country);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Link href="#" onClick={handleClickOpen} style={{color: 'rgb(1, 172, 230)', fontWeight: "bold",}}>
                {textTranslated.more_info}
            </Link>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {textTranslated.label_why_consent}
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {textTranslated.more_info_why_consent}
                    </Typography>
                </DialogContent>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {textTranslated.label_channels}
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {textTranslated.more_info_channels}
                    </Typography>
                </DialogContent>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {textTranslated.label_optout}
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {textTranslated.more_info_optout}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        {textTranslated.label_close}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
MoreInfo.propTypes = {
    country: PropTypes.number.isRequired
}
